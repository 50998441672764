var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c(
      "div",
      [
        _c(
          "b-card",
          [
            _c(
              "b-row",
              { staticClass: " mt-n2 ml-0" },
              [
                _c(
                  "b-col",
                  { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-5 mt-2" },
                        [
                          _c(
                            "b-avatar",
                            {
                              attrs: { variant: "light-primary", rounded: "" }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CalendarIcon", size: "18" }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _c("ul", { staticClass: "list-inline mb-0" }, [
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [_vm._v("223")]
                                )
                              ]),
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "text-success font-weight-bold small"
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowUpIcon" }
                                    }),
                                    _vm._v(" 28% ")
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("small", [_vm._v("Total Shifts")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-5 mt-2" },
                        [
                          _c(
                            "b-avatar",
                            {
                              attrs: { variant: "light-success", rounded: "" }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CheckIcon", size: "18" }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _c("ul", { staticClass: "list-inline mb-0" }, [
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [_vm._v("116")]
                                )
                              ]),
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "text-success font-weight-bold small"
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowUpIcon" }
                                    }),
                                    _vm._v(" 14% ")
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("small", [_vm._v("Filled Shifts")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-5 mt-2" },
                        [
                          _c(
                            "b-avatar",
                            {
                              attrs: { variant: "light-warning", rounded: "" }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CrosshairIcon", size: "18" }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _c(
                              "h4",
                              { staticClass: "mb-0 font-weight-bolder" },
                              [_vm._v("220 ")]
                            ),
                            _c("small", [_vm._v("Target Hours")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-5 mt-2" },
                        [
                          _c(
                            "b-avatar",
                            {
                              attrs: { variant: "light-success", rounded: "" }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CheckIcon", size: "18" }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _c("ul", { staticClass: "list-inline mb-0" }, [
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [_vm._v("167")]
                                )
                              ]),
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "text-success font-weight-bold small"
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowUpIcon" }
                                    }),
                                    _vm._v(" 12% ")
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("small", [_vm._v("Completed Hours")])
                          ])
                        ],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "b-row",
          { staticClass: "match-height" },
          [
            _c(
              "b-col",
              { attrs: { lg: "7" } },
              [_c("b-card", [_c("ShiftsStafftype")], 1)],
              1
            ),
            _c("b-col", { attrs: { lg: "5" } }, [_c("ShiftsStatus")], 1)
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "b-row",
          [
            _c("b-col", { attrs: { lg: "7" } }, [_c("ClinicalHourse")], 1),
            _c("b-col", { attrs: { lg: "5" } }, [_c("TargetClinicalHourse")], 1)
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }