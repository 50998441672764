var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c(
        "b-card-header",
        { staticClass: "mt-n2" },
        [
          _c("div", { staticClass: "d-flex align-items-center " }, [
            _c("div", [
              _c("h3", { staticClass: "mb-0 font-weight-bolder " }, [
                _vm._v("Target Clinical Hours")
              ])
            ])
          ]),
          _c(
            "b-card-text",
            { staticClass: "font-medium-5 mb-0" },
            [
              _c("feather-icon", {
                staticClass: "text-muted cursor-pointer",
                attrs: { icon: "HelpCircleIcon", size: "21" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("vue-apex-charts", {
        staticClass: "my-2",
        attrs: {
          type: "radialBar",
          height: "363",
          options: _vm.option,
          series: _vm.series
        }
      }),
      _c(
        "b-row",
        { staticClass: "text-center mx-0" },
        [
          _c(
            "b-col",
            {
              staticClass:
                "border-top border-right d-flex align-items-between flex-column py-1",
              attrs: { cols: "6" }
            },
            [
              _c("h3", { staticClass: "font-weight-bolder mb-0" }, [
                _vm._v(" 167h ")
              ]),
              _c("b-card-text", { staticClass: "text-muted mb-0" }, [
                _vm._v(" Completed ")
              ])
            ],
            1
          ),
          _c(
            "b-col",
            {
              staticClass:
                "border-top d-flex align-items-between flex-column py-1",
              attrs: { cols: "6" }
            },
            [
              _c("h3", { staticClass: "font-weight-bolder mb-0" }, [
                _vm._v(" 35h ")
              ]),
              _c("b-card-text", { staticClass: "text-muted mb-0" }, [
                _vm._v(" Scheduled ")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }