var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Home" },
    [
      _c(
        "b-row",
        { staticClass: " " },
        [
          _c("b-col", { attrs: { cols: "12", xl: "7" } }),
          _c(
            "b-col",
            {
              staticClass: "pt-0 pb-2 pt-xl-2 pb-xl-0",
              attrs: { cols: "12", xl: "5" }
            },
            [
              _c(
                "b-row",
                {},
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "text-right mb-1",
                      attrs: { cols: "12", md: "7", xl: "7" }
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "b-button-group",
                            { staticClass: "bg-white" },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(113, 102, 240, 0.15)",
                                      expression: "'rgba(113, 102, 240, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  attrs: { variant: "outline-primary" }
                                },
                                [_vm._v(" Week ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(113, 102, 240, 0.15)",
                                      expression: "'rgba(113, 102, 240, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  attrs: { variant: "outline-secondary" }
                                },
                                [_vm._v(" Month ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(113, 102, 240, 0.15)",
                                      expression: "'rgba(113, 102, 240, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  attrs: { variant: "outline-secondary" }
                                },
                                [_vm._v(" Year ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "5", xl: "5" } },
                    [
                      _c(
                        "b-form-group",
                        [
                          _c("v-select", {
                            staticClass: "bg-white",
                            staticStyle: { width: "210px" },
                            attrs: {
                              placeholder: "This Week: Feb 22 - Feb 28",
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              label: "title3",
                              options: _vm.option3
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-tabs",
        [
          _c("b-tab", { attrs: { title: "Hours and Shifts" } }, [
            _c("div", { staticClass: "Alert-body mt-n1 " }, [_c("Hours")], 1)
          ]),
          _c(
            "b-tab",
            {
              attrs: { title: "Staff" },
              on: {
                click: function($event) {
                  return _vm.$refs.staff.changesKey()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "Alert-body mt-n1" },
                [_c("Staff", { ref: "staff" })],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }