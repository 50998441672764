<template>
 <b-card>



      <b-card-header class="mt-n2">
       <div class="d-flex align-items-center ">

                <div>
                  <h3 class="mb-0 font-weight-bolder ">Clinical Hours</h3>
                </div>
                </div>

      <b-card-text class="font-medium-5 mb-0">
        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>

  <b-card-body>
    <app-echart-bar
      :option-data="option"
    />
  </b-card-body>
 </b-card >
</template>

<script>


import { BAvatar, BBadge, BAlert, BButton, BCard, BCol, BLink, BRow, BCardHeader, BCardText, BCardBody } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'

export default {
  components: {
    AppEchartBar,
    BAvatar,
    BBadge,
    BAlert,
    BButton,
    BCard,
    BCol,
    BLink,
    BRow,
    BCardHeader,
    BCardText,
    BCardBody,
  },
  data() {
    return {
      option: {
        legend: {
          show: false,
          left: '0',
          bottom: '0',
        },
        xAxis: [
          {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun' ],
            splitLine: {show:false},
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: true },
          },
        ],
        grid: {
          left: '40px',
          right: '0px',
          bottom: '20px',
        },
        series: [
          {
            name: 'Clinical Hours',
            type: 'bar',
            stack: 'advertising',
            data: [58, 68, 72, 55, 67, 40, 25],
            barMaxWidth: '20%',
            barMinWidth: '10px',
             itemStyle: {
            barBorderRadius: [10, 10, 0, 0],
            color: [ "#1B9AAA"],
            },
          },
        ],
      },
    }
  },
}
</script>
