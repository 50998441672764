var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home" }, [
    _c(
      "div",
      [
        _c(
          "b-card",
          [
            _c(
              "b-row",
              { staticClass: " mt-n2 ml-0" },
              [
                _c(
                  "b-col",
                  { staticClass: "pt-0 pb-0", attrs: { cols: "12" } },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-5 mt-2" },
                        [
                          _c(
                            "b-avatar",
                            { attrs: { variant: "light-info", rounded: "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "UsersIcon", size: "18" }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _c("ul", { staticClass: "list-inline mb-0" }, [
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [_vm._v("868")]
                                )
                              ]),
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "text-success font-weight-bold small"
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowUpIcon" }
                                    }),
                                    _vm._v(" 8% ")
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("small", [_vm._v("Total Staff")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-5 mt-2" },
                        [
                          _c("b-avatar", {
                            attrs: {
                              text: "S",
                              variant: "light-success",
                              rounded: "",
                              size: "35"
                            }
                          }),
                          _c("div", { staticClass: "ml-1" }, [
                            _c("ul", { staticClass: "list-inline mb-0" }, [
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [_vm._v("648")]
                                )
                              ]),
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "text-danger font-weight-bold small"
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowDownIcon" }
                                    }),
                                    _vm._v(" 3% ")
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("small", [_vm._v("Salaried Staff")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-5 mt-2" },
                        [
                          _c("b-avatar", {
                            attrs: {
                              text: "L",
                              variant: "light-primary",
                              rounded: "",
                              size: "35"
                            }
                          }),
                          _c("div", { staticClass: "ml-1" }, [
                            _c("ul", { staticClass: "list-inline mb-0" }, [
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [_vm._v("220")]
                                )
                              ]),
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "text-success font-weight-bold small"
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowUpIcon" }
                                    }),
                                    _vm._v(" 12% ")
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("small", [_vm._v("Locums")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-5 mt-2" },
                        [
                          _c(
                            "b-avatar",
                            {
                              attrs: { variant: "light-warning", rounded: "" }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "UserIcon", size: "18" }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _c("ul", { staticClass: "list-inline mb-0" }, [
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [_vm._v("89")]
                                )
                              ]),
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "text-danger font-weight-bold small"
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowDownIcon" }
                                    }),
                                    _vm._v(" 2% ")
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("small", [_vm._v("Total Applications")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-5 mt-2" },
                        [
                          _c(
                            "b-avatar",
                            {
                              attrs: { variant: "light-success", rounded: "" }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "CheckSquareIcon", size: "18" }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _c("ul", { staticClass: "list-inline mb-0" }, [
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [_vm._v("23")]
                                )
                              ]),
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "text-success font-weight-bold small"
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowUpIcon" }
                                    }),
                                    _vm._v(" 4% ")
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("small", [_vm._v("Accepted Applications")])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center mr-5 mt-2" },
                        [
                          _c(
                            "b-avatar",
                            { attrs: { variant: "light-danger", rounded: "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "XSquareIcon", size: "18" }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-1" }, [
                            _c("ul", { staticClass: "list-inline mb-0" }, [
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [_vm._v("66")]
                                )
                              ]),
                              _c("li", { staticClass: "list-inline-item" }, [
                                _c(
                                  "small",
                                  {
                                    staticClass:
                                      "text-danger font-weight-bold small"
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "ArrowDownIcon" }
                                    }),
                                    _vm._v(" 1.5% ")
                                  ],
                                  1
                                )
                              ])
                            ]),
                            _c("small", [_vm._v("Rejected Applications")])
                          ])
                        ],
                        1
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "b-row",
          { staticClass: "match-height" },
          [
            _c(
              "b-col",
              { attrs: { lg: "4" } },
              [_c("b-card", [_c("BankAvailability")], 1)],
              1
            ),
            _c(
              "b-col",
              { attrs: { lg: "4" } },
              [_c("b-card", [_c("BankType")], 1)],
              1
            ),
            _c(
              "b-col",
              { attrs: { lg: "4" } },
              [_c("b-card", [_c("Applications")], 1)],
              1
            )
          ],
          1
        ),
        _c(
          "b-row",
          { staticClass: "match-height" },
          [
            _c(
              "b-col",
              { attrs: { lg: "12" } },
              [_c("RoleBreakdown", { ref: "roleBreakdown" })],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }