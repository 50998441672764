<template>
      <b-card>

        <b-card-header class="mt-n2">
                <div class="d-flex align-items-center  ">
                    <div>
                      <h3 class="mb-0 font-weight-bolder "> Shifts </h3>
                    </div>

                   <div class="ml-1 mt-1">
                      <b-alert   variant="primary" show>
                          <small class="text-dark mx-1 "> Status </small>
                      </b-alert>
                    </div>
                </div>

               <b-card-text class="font-medium-5 mb-0 mr-2">
                   <feather-icon icon="HelpCircleIcon" size="21" class="text-muted cursor-pointer" />
               </b-card-text>
        </b-card-header>

             <!-- echart -->
             <b-card-body class="mt-n3 mb-n3" >
                
                <div class="ml-n4">
                        <e-charts
                        :options="option"
                        theme="theme-color"
                        
                        auto-resize />
                          <div class="pie-text text-center">
                        <span span class="font-weight-bold">Total Shifts</span>
                          <h1 class="font-weight-bolder text-dark"> 223 </h1>
                </div>
                </div>
             </b-card-body>
          <div>
          <b-row class=" mt-n2 ml-1">

             <b-col cols="12" class="pt-0 pb-2 pt-xl-2 pb-xl-0  ">
                <div class="d-flex align-items-center ">

               <!-- 01 Filled Shifts -->

                    <div class="d-flex align-items-center ">

                      <b-avatar style="background:#5cb85c !important" rounded size="10" class="mt-n1">
                        <feather-icon icon="CheckIcon" size="1" />
                      </b-avatar>

                      <div class="ml-1">
                         <ul class="list-inline mb-0">
                             <h4 class="mb-0 font-weight-bolder">116</h4>
                         </ul>
                           <small>Filled Shifts</small>
                    </div>
                    </div>
                        <!-- 02 Vacant Shifts -->

                     <div class="d-flex align-items-center ml-1">

                           <b-avatar style="background:#0275d8 !important"  rounded size="10" class="mt-n1">
                             <feather-icon icon="CheckIcon" size="1" />
                           </b-avatar>

                      <div class="ml-1">
                           <ul class="list-inline mb-0">
                               <h4 class="mb-0 font-weight-bolder">47</h4>
                           </ul>
                           <small>Vacant Shifts</small>
                      </div>
                     </div>

                         <!-- 03 Pending Shifts -->

                       <div class="d-flex align-items-center ml-1">

                           <b-avatar style="background:#f0ad4e !important" rounded size="10" class="mt-n1">
                              <feather-icon icon="CheckIcon" size="1" />
                           </b-avatar>

                           <div class="ml-1">
                                <ul class="list-inline mb-0">
                                    <h4 class="mb-0 font-weight-bolder">40</h4>
                               </ul>
                               <small>Pending Shifts</small>
                            </div>
                        </div>


                          <!-- 04 Cancalled Shifts -->

                        <div class="d-flex align-items-center ml-1">

                             <b-avatar style="background:#f7f7f7 !important" rounded size="10" class="mt-n1">
                                 <feather-icon icon="CheckIcon" size="1" />
                              </b-avatar>

                              <div class="ml-1">
                                  <ul class="list-inline mb-0">
                                      <h4 class="mb-0 font-weight-bolder">20</h4>
                                  </ul>
                                     <small>Cancelled Shifts</small>
                              </div>
                        </div>

            </div>

             </b-col>

          </b-row>
        </div>
      </b-card>
</template>

<script>

import ECharts from 'vue-echarts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/chart/pie'
import {BAlert, BCard, BCardHeader, BRow, BCol, BCardText, BAvatar, BCardBody} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BCard,
    BAlert,
    BCardHeader,
    BRow,
    BCol,
    BCardText,
    ECharts,
    BCardBody,
  },
  props: {

  },
  data() {
    return {
      option: {
        tooltip: {
          trigger: 'item',
          formatter: '{b}:  {d}%',
        },
        legend: {
          show: false,
          left: '0',
          bottom: '0',
        },

      series: [
        {
          name: 'Visit source',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: 116, name: 'Filled Shifts' },
            { value: 47, name: 'Vacant Shifts' },
            { value: 40, name: 'Pending Shifts' },
            { value: 20, name: 'Cancelled Shifts' },
          ],
           color: [
                     "#00C49A",
                     "#4361EE",
                     "#EFA12C",
                     "#E5E5E5"
            ],
        },
      ],
      }
    }
  },
}
</script>

<style lang="scss">
.card-body{
  position: relative;
  .pie-text{
    width: 105px;
    position:absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 43%;
    bottom: 0;
  }
}
</style>
