<template>
  <b-card no-body>
    <b-card-header class="mt-n2">
       <div class="d-flex align-items-center ">

                <div>
                  <h3 class="mb-0 font-weight-bolder "> Shifts </h3>
                </div>

                   <div class="ml-1 mt-1">
                      <b-alert   variant="primary" show>
                          <small class="text-dark mx-1 "> Staff Type </small>
                      </b-alert>
                    </div>
                </div>

                <div class="ml-auto mt-1">
                 <b-row>
                    <b-avatar variant="primary" square size="15" class="mt-1">
                        <feather-icon icon="FileIcon" size="1"/>
                    </b-avatar>

                   <div class="ml-1">
                     <h4 class="mb-0 font-weight-bolder "> 170 </h4>
                     <small>Salaried Staff Shifts</small>
                    </div>
                    <div class="ml-1">
                     <b-avatar variant="light-primary" square size="15" class="mt-1">
                        <feather-icon icon="FileIcon" size="1"/>
                    </b-avatar>
                    </div>
                   <div class="ml-1">
                     <h4 class="mb-0 font-weight-bolder "> 53 </h4>
                     <small>Locum Shifts</small>
                    </div>
                 </b-row>
                </div>
      <b-card-text class="font-medium-5 mb-0 ml-3">

        <feather-icon
          icon="HelpCircleIcon"
          size="21"
          class="text-muted cursor-pointer"
        />
      </b-card-text>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-line-chart
          :key="custom_key"
        :height="400"
        :data="chartjsData.lineChart.data"
        :options="chartjsData.lineChart.options"
        :plugins="plugins"
      />
    </b-card-body>
  </b-card>
</template>

<script>

import {
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,BAvatar, BBadge, BAlert, BButton,BCol, BLink, BRow, BCardText
} from 'bootstrap-vue'
import ChartjsComponentLineChart from '../../chartcomponents/ChartjsComponentLineChart'
import chartjsData from '../../chartcomponents/chartjsData'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    ChartjsComponentLineChart,
    BAvatar,
    BBadge,
    BAlert,
    BButton,
    BCol,
    BLink,
    BRow,
    BCardText
  },
  data() {
    return {
      custom_key : 0,
      chartjsData,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.height = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  mounted() {
    this.custom_key ++
  },
  directives: {
    Ripple,
  },
}
</script>
