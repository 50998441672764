var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    [
      _c(
        "b-card-header",
        { staticClass: "mt-n2" },
        [
          _c("div", { staticClass: "d-flex align-items-center  " }, [
            _c("div", [
              _c("h3", { staticClass: "mb-0 font-weight-bolder " }, [
                _vm._v(" Shifts ")
              ])
            ]),
            _c(
              "div",
              { staticClass: "ml-1 mt-1" },
              [
                _c("b-alert", { attrs: { variant: "primary", show: "" } }, [
                  _c("small", { staticClass: "text-dark mx-1 " }, [
                    _vm._v(" Status ")
                  ])
                ])
              ],
              1
            )
          ]),
          _c(
            "b-card-text",
            { staticClass: "font-medium-5 mb-0 mr-2" },
            [
              _c("feather-icon", {
                staticClass: "text-muted cursor-pointer",
                attrs: { icon: "HelpCircleIcon", size: "21" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("b-card-body", { staticClass: "mt-n3 mb-n3" }, [
        _c(
          "div",
          { staticClass: "ml-n4" },
          [
            _c("e-charts", {
              attrs: {
                options: _vm.option,
                theme: "theme-color",
                "auto-resize": ""
              }
            }),
            _c("div", { staticClass: "pie-text text-center" }, [
              _c(
                "span",
                { staticClass: "font-weight-bold", attrs: { span: "" } },
                [_vm._v("Total Shifts")]
              ),
              _c("h1", { staticClass: "font-weight-bolder text-dark" }, [
                _vm._v(" 223 ")
              ])
            ])
          ],
          1
        )
      ]),
      _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: " mt-n2 ml-1" },
            [
              _c(
                "b-col",
                {
                  staticClass: "pt-0 pb-2 pt-xl-2 pb-xl-0  ",
                  attrs: { cols: "12" }
                },
                [
                  _c("div", { staticClass: "d-flex align-items-center " }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center " },
                      [
                        _c(
                          "b-avatar",
                          {
                            staticClass: "mt-n1",
                            staticStyle: { background: "#5cb85c !important" },
                            attrs: { rounded: "", size: "10" }
                          },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "CheckIcon", size: "1" }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "ml-1" }, [
                          _c("ul", { staticClass: "list-inline mb-0" }, [
                            _c(
                              "h4",
                              { staticClass: "mb-0 font-weight-bolder" },
                              [_vm._v("116")]
                            )
                          ]),
                          _c("small", [_vm._v("Filled Shifts")])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center ml-1" },
                      [
                        _c(
                          "b-avatar",
                          {
                            staticClass: "mt-n1",
                            staticStyle: { background: "#0275d8 !important" },
                            attrs: { rounded: "", size: "10" }
                          },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "CheckIcon", size: "1" }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "ml-1" }, [
                          _c("ul", { staticClass: "list-inline mb-0" }, [
                            _c(
                              "h4",
                              { staticClass: "mb-0 font-weight-bolder" },
                              [_vm._v("47")]
                            )
                          ]),
                          _c("small", [_vm._v("Vacant Shifts")])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center ml-1" },
                      [
                        _c(
                          "b-avatar",
                          {
                            staticClass: "mt-n1",
                            staticStyle: { background: "#f0ad4e !important" },
                            attrs: { rounded: "", size: "10" }
                          },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "CheckIcon", size: "1" }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "ml-1" }, [
                          _c("ul", { staticClass: "list-inline mb-0" }, [
                            _c(
                              "h4",
                              { staticClass: "mb-0 font-weight-bolder" },
                              [_vm._v("40")]
                            )
                          ]),
                          _c("small", [_vm._v("Pending Shifts")])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-center ml-1" },
                      [
                        _c(
                          "b-avatar",
                          {
                            staticClass: "mt-n1",
                            staticStyle: { background: "#f7f7f7 !important" },
                            attrs: { rounded: "", size: "10" }
                          },
                          [
                            _c("feather-icon", {
                              attrs: { icon: "CheckIcon", size: "1" }
                            })
                          ],
                          1
                        ),
                        _c("div", { staticClass: "ml-1" }, [
                          _c("ul", { staticClass: "list-inline mb-0" }, [
                            _c(
                              "h4",
                              { staticClass: "mb-0 font-weight-bolder" },
                              [_vm._v("20")]
                            )
                          ]),
                          _c("small", [_vm._v("Cancelled Shifts")])
                        ])
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }