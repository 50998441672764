var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-header",
        { staticClass: "mt-n1" },
        [
          _c("div", { staticClass: "d-flex align-items-center " }, [
            _c("div", [
              _c("h3", { staticClass: "mb-0 font-weight-bolder " }, [
                _vm._v(" Applications ")
              ])
            ])
          ]),
          _c(
            "b-card-text",
            { staticClass: "font-medium-5 mb-0" },
            [
              _c("feather-icon", {
                staticClass: "text-muted cursor-pointer",
                attrs: { icon: "HelpCircleIcon", size: "21" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "ml-n3 mt-n2 " }, [
        _c(
          "div",
          { staticClass: "ml-n5 mt-n3" },
          [_c("app-echart-doughnut", { attrs: { series: _vm.series } })],
          1
        )
      ]),
      _c("div", [
        _c("div", { staticClass: "d-flex justify-content-center mt-n4" }, [
          _c("div", { staticClass: "p-2" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center " },
              [
                _c(
                  "b-avatar",
                  {
                    staticClass: "mt-n1",
                    attrs: { variant: "success", rounded: "", size: "10" }
                  },
                  [
                    _c("feather-icon", {
                      attrs: { icon: "CheckIcon", size: "1" }
                    })
                  ],
                  1
                ),
                _vm._m(0)
              ],
              1
            )
          ]),
          _c("div", { staticClass: "p-2" }, [
            _c(
              "div",
              { staticClass: "d-flex align-items-center " },
              [
                _c(
                  "b-avatar",
                  {
                    staticClass: "mt-n1",
                    attrs: { variant: "danger", rounded: "", size: "10" }
                  },
                  [
                    _c("feather-icon", {
                      attrs: { icon: "CheckIcon", size: "1" }
                    })
                  ],
                  1
                ),
                _vm._m(1)
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-1" }, [
      _c("ul", { staticClass: "list-inline mb-0" }, [
        _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [_vm._v("23")])
      ]),
      _c("small", [_vm._v("Accepted")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-1" }, [
      _c("ul", { staticClass: "list-inline mb-0" }, [
        _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [_vm._v("66")])
      ]),
      _c("small", [_vm._v("Rejected")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }