var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        {},
        [
          _c("div", { staticClass: "d-flex align-items-center " }, [
            _c("div", [
              _c("h3", { staticClass: "mb-0 font-weight-bolder " }, [
                _vm._v(" Role Breakdown ")
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "ml-auto " },
            [
              _c("b-row", [
                _c(
                  "div",
                  { staticClass: "ml-1" },
                  [
                    _c(
                      "b-avatar",
                      {
                        attrs: { variant: "success", rounded: "", size: "10" }
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "CheckIcon", size: "1" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "ml-1" }, [
                  _c("small", [_vm._v("Salaried Staff")])
                ]),
                _c(
                  "div",
                  { staticClass: "ml-1" },
                  [
                    _c(
                      "b-avatar",
                      { attrs: { variant: "info", rounded: "", size: "10" } },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "CheckIcon", size: "1" }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "ml-1" }, [
                  _c("small", [_vm._v("Locums")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-card-text",
            { staticClass: "font-medium-5 mb-0 ml-3" },
            [
              _c("feather-icon", {
                staticClass: "text-muted cursor-pointer",
                attrs: { icon: "HelpCircleIcon", size: "21" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c("chartjs-component-bubble-chart", {
            key: _vm.custom_key,
            attrs: {
              height: 400,
              data: _vm.chartjsData.bubbleChart.data,
              options: _vm.chartjsData.bubbleChart.options,
              plugins: _vm.plugins
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }