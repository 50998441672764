var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        { staticClass: "mt-n2" },
        [
          _c("div", { staticClass: "d-flex align-items-center " }, [
            _c("div", [
              _c("h3", { staticClass: "mb-0 font-weight-bolder " }, [
                _vm._v(" Shifts ")
              ])
            ]),
            _c(
              "div",
              { staticClass: "ml-1 mt-1" },
              [
                _c("b-alert", { attrs: { variant: "primary", show: "" } }, [
                  _c("small", { staticClass: "text-dark mx-1 " }, [
                    _vm._v(" Staff Type ")
                  ])
                ])
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "ml-auto mt-1" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-avatar",
                    {
                      staticClass: "mt-1",
                      attrs: { variant: "primary", square: "", size: "15" }
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "FileIcon", size: "1" }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("h4", { staticClass: "mb-0 font-weight-bolder " }, [
                      _vm._v(" 170 ")
                    ]),
                    _c("small", [_vm._v("Salaried Staff Shifts")])
                  ]),
                  _c(
                    "div",
                    { staticClass: "ml-1" },
                    [
                      _c(
                        "b-avatar",
                        {
                          staticClass: "mt-1",
                          attrs: {
                            variant: "light-primary",
                            square: "",
                            size: "15"
                          }
                        },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "FileIcon", size: "1" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ml-1" }, [
                    _c("h4", { staticClass: "mb-0 font-weight-bolder " }, [
                      _vm._v(" 53 ")
                    ]),
                    _c("small", [_vm._v("Locum Shifts")])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-card-text",
            { staticClass: "font-medium-5 mb-0 ml-3" },
            [
              _c("feather-icon", {
                staticClass: "text-muted cursor-pointer",
                attrs: { icon: "HelpCircleIcon", size: "21" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card-body",
        [
          _c("chartjs-component-line-chart", {
            key: _vm.custom_key,
            attrs: {
              height: 400,
              data: _vm.chartjsData.lineChart.data,
              options: _vm.chartjsData.lineChart.options,
              plugins: _vm.plugins
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }